<template>
    <modal :show="show" @hide="closeModal" size="lg" class="address-book-modal">
        <div slot="header" class="flex justify-between w-full pr-5">
            <h2>Company address book</h2>

            <div class="flex">
                <modal-field class="mr-4 w-64">
                    <autocomplete
                        v-model="searchString"
                        :endpoint="(val) => `${$apiUrl.addressBook.base}?${getSearchFilter({ search: val, organization_id: owner.id })}`"
                        placeholder="Search address"
                        hide-list-on-enter
                        :on-options-loaded="processAddresses"
                        @click="searchSelect"
                        @enter="searchCompanies"
                    >
                        <template v-slot="slotProps">
                            {{ slotProps.data.name }}
                            <span v-text="slotProps.data.complete_address" class="text-gray-700" />
                        </template>
                    </autocomplete>
                </modal-field>
                <button class="mr-4" @click="$emit('open-create-address')">
                    <svg-importer icon-name="icons/add-home" />
                </button>
                <multiselect
                    v-model="localOwner"
                    :options="organizations"
                    track-by="id"
                    label="name"
                    placeholder="Select owner"
                    :disabled="!isAdmin || loading"
                    class="w-48"
                />
            </div>
        </div>
        <div slot="body" class="-mx-6 overflow-auto max-h-50 h-50vh">
            <table class="table w-full border-b address-table">
                <tr>
                    <th v-for="(column, index) in columns" :key="`address-book-column_${index}`">
                        <div class="flex flex__align-center" :class="{ 'cursor-pointer': column.sortable }" @click="sort(column.id)">
                            <div v-if="column.sortable">
                                <svg-importer v-show="currentSort(column.id, 1)" :key="`${column.id}_up`" icon-name="icons/table-sort-up" />
                                <svg-importer v-show="currentSort(column.id, -1)" :key="`${column.id}_down`" icon-name="icons/table-sort-down" />
                                <svg-importer v-show="currentSortKey !== column.id" :key="`${column.id}_default`" icon-name="icons/table-sort" />
                            </div>

                            <span>
                                {{ column.name }}
                            </span>
                        </div>
                    </th>
                </tr>

                <template v-if="loading">
                    <tr v-for="n in loadingRows" :key="n">
                        <td v-for="(i, index) in columns" :key="i.id">
                            <span
                                class="h-4 inline-block text-transparent linear-background"
                                :class="{ 'w__50': index === 0, 'w-3/4': index }"
                            >
                                Loading
                            </span>
                        </td>
                    </tr>
                </template>

                <template v-else>
                    <tr
                        v-for="(company, index) in filterCompanies"
                        :key="company + '-company-'+ index"
                        @click="markCompany(company)"
                        v-on:dblclick="editAddress(company)"
                    >

                        <td class="px-4 text-center">
                            <input type="radio" v-model="selectedCompany" :value="company">
                        </td>
                        <td :title="prepareTypesList(company.types)">
                            {{ prepareTypesList(company.types) }}
                        </td>
                        <td class="overflow-truncate-long">{{ company.name }}</td>
                        <td>{{ company.address}}</td>
                        <td>{{ company.postcode }}</td>
                        <td>{{ company.state }}</td>
                        <td>{{ company.city }}</td>
                        <td>{{ company.country_code }}</td>
                    </tr>
                    <tr v-show="!filterCompanies.length" class="text-center">
                        <td colspan="8">
                            No results
                        </td>
                    </tr>
                </template>    
            </table>
        </div>

        <div slot="footer" class="flex flex__column w-full">
            <pagination
                :table="table"
                class="mb-4 address-book-pagination"
                @go-to-prev="goToPrev"
                @go-to-page="goToPage"
                @go-to-next="goToNext"
                @page-size="updatePageSize"
            />

            <div class="flex flex__justify-between w-full">
                <div class="flex flex-grow">
                    <button
                        v-text="`Cancel`"
                        class="ml-auto mr-4 text-sm text-red-500"
                        @click="closeModal"
                    />

                    <button
                        v-text="`Dublicate & edit`"
                        class="mr-4 text-sm btn-primary"
                        :disabled="!selectedCompany"
                        @click="$emit('dublicate')"
                    />

                    <button
                        v-text="`Select`"
                        class="btn"
                        :disabled="!selectedCompany"
                        @click="$emit('choose')"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import axios from 'axios';
import Pagination from './AddressBookPagination.vue';
import Autocomplete from '~/components/Autocomplete.vue';
import ModalField from "~/components/ModalField";

export const paginationLinksReducer = (accum, link, index, linksArray) => {
    link.value = link.label;

    if (link.label === '...') {
        const prevValue = +linksArray[index - 1]?.label;
        const nextValue = +linksArray[index + 1]?.label

        const diff = nextValue - prevValue;
        link.value = prevValue + Math.round(diff / 2);
    }

    if (!isNaN(parseInt(link.value))) {
        accum.push(link);
    }

    return accum
};

export const TABLE_PAGINATION_DEFAULT = {
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
    last_page: 1,
    links: [],
    per_page: 50,
};

const addressParentToSearchTypes = {
    consignor: 'SENDER',
    pickup: 'PICK_UP',
    consignee: 'RECEIVER',
    delivery: 'DELIVERY',
};

export default {
    components: {
         Pagination,
         Autocomplete,
         ModalField,
    },
    props: {
        show: Boolean,
        owner: {
            type: Object,
            default: () => null,
        },
        selectedCompany: {
            type: Object,
            default: () => null,
        },
        addressParent: String,
        cities: {
            type: Array,
            default: () => [],
        },
        organizations: {
            type: Array,
            default: () => [],
        }
    },
    model: {
        prop: 'owner',
        event: 'update:owner',
    },
    data() {
        return {
            currentSortKey: '',
            currentSortOrder: '',
            searchString: '',
            loading: true,
            table: { ...TABLE_PAGINATION_DEFAULT },
            columns: [
                { id: 'check', name: '', sortable: false },
                { id: 'type', name: 'Type', sortable: false },
                { id: 'name', name: 'Company name', sortable: true },
                { id: 'address', name: 'Address', sortable: true },
                { id: 'postcode', name: 'Zip', sortable: true },
                { id: 'state', name: 'State', sortable: true },
                { id: 'city', name: 'City', sortable: true },
                { id: 'country', name: 'Country', sortable: true },
            ],
            companies: [],
            loadingRows: new Array(5),
        };
    },
    computed: {
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        filterCompanies() {
            return this.addressParent ? this.companies : [];
        },
        localOwner: {
            set(value) {
                this.$emit('update:owner', value);
            },
            get() {
                return this.owner;
            },
        },
    },
    watch: {
        owner(val) {
            this.resetTable();

            if (!val || !this.show) {
                return;
            }

            this.fetchCompanies(val);
        },
        show(val) {
            this.searchString = '';
            this.resetTable();

            if (!val) {
                return;
            }

            this.fetchCompanies();
        },
    },
    methods: {
        getSearchFilter({ search, organization_id }) {
            return `filter[search]=${search}&filter[organization_id]=${organization_id}&filter[types]=${addressParentToSearchTypes[this.addressParent]}`;
        },
        sort(sortKey) {
            if (this.currentSortKey !== sortKey) {
                this.currentSortKey = sortKey;
                this.currentSortOrder = 1;
            } else if (this.currentSortOrder === 1) {
                this.currentSortOrder = -1;
            } else if (this.currentSortOrder === '') {
                this.currentSortOrder = 1;
            } else {
                this.currentSortKey = '';
                this.currentSortOrder = '';
            }

            this.fetchCompanies();
        },
        currentSort(sortKey, sortOrder) {
            return sortKey === this.currentSortKey && this.currentSortOrder === sortOrder;
        },
        searchSelect(val) {
            this.markCompany(val);
            setTimeout(() => {
                this.$emit('choose')
            }, 100);
        },
        resetTable() {
            this.table = { ...TABLE_PAGINATION_DEFAULT };
        },
        goToPrev() {
            this.table.current_page--;

            this.fetchCompanies();
        },
        goToPage(page) {
            if (this.table.current_page === +page) {
                return;
            }
            
            this.table.current_page = +page;

            this.fetchCompanies();
        },
        goToNext() {
            this.table.current_page++;

            this.fetchCompanies();
        },
        updatePageSize(value) {
            this.table.per_page = value;
            this.table.current_page = 1;

            this.fetchCompanies();
        },
        closeModal() {
            this.companies = [];
            this.$emit('hide');
        },
        editAddress(event) {
            this.$emit('dblclick', event);
        },
        prepareTypesList(types) {
            if (typeof types === 'string') {
                types = JSON.parse(types);
            }

            if (!Array.isArray(types)) {
                return '-';
            }
            
            return types.map(item => {
                return this.companyTypeDictionary.find(type => type.value === item )?.name;
            }).join(', ');
        },
        markCompany(event) {
            this.$emit('click', event);
        },
        getAddressBookParams() {
            let params = `?&pageSize=${this.table.per_page}`
                + `&page=${this.table.current_page}`
                + `&filter[organization_id]=${this.owner.id}`
                + `&filter[types]=${addressParentToSearchTypes[this.addressParent]}`;

            if (this.searchString) {
                params += `&filter[search]=${this.searchString}`;
            }

            if (this.currentSortKey && this.currentSortOrder) {
                params += `&sortKey=${this.currentSortKey}&sortOrder=${this.currentSortOrder}`;
            }

            return params;
        },
        processAddresses(data) {
            return data.map(company => {
                const city = this.cities
                    .find(cityItem => cityItem.id === company.city_id);

                if (!city) {
                    return company;
                }

                company.city = city.name;
                company.country_code = city.country_code;

                return company;
            });
        },
        async fetchCompanies() {
            if (!this.loading) {
                this.loading = true;
            }

            const { data } = await axios.get(`${this.$apiUrl.addressBook.base}/${this.getAddressBookParams()}`);

            data.meta.links = data.meta.links.reduce(paginationLinksReducer, []);
            this.table = { ...data.meta };

            const companiesList = this.processAddresses(data.data);

            this.companies = companiesList;
            this.loading = false;
        },
        searchCompanies() {
            this.table.current_page = 1;

            this.fetchCompanies();
        },
    },
}
</script>
<style lang="scss">
.address-book-modal {
    .autocomplete input {
        margin-top: 0;
    }
}

.address-book-pagination {
    margin-top: -16px;
}
</style>