<template>
    <div v-if="table.total > 0" class="relative flex flex__wrap flex__justify-between items-center w-full py-2">
        <span
            class="text-body no-wrap"
        >
            {{ table.from }}-{{ table.to }} of {{ table.total }}
        </span>
        <div v-if="table.last_page > 1" class="pagination">
            <button
                class="page-item prev"
                :class="{'disabled': table.current_page === 1}"
                @click="$emit('go-to-prev')"
            >
                <svg-importer icon-name="icons/arrow-left" key="pagniate-arrow-left" />
            </button>
            <template v-for="n in table.links">
                <button
                    v-if="renderNavItem(n)"
                    :key="n.label"
                    class="page-item"
                    :class="{'active': n.active }"
                    @click="$emit('go-to-page', n.value)"
                >
                    {{ n.label }}
                </button>
            </template>
            <button
                class="page-item next"
                :class="{'disabled': table.current_page === table.last_page}"
                @click="$emit('go-to-next')"
            >
                <svg-importer icon-name="icons/arrow-right" key="pagniate-arrow-right" />
            </button>
        </div>
        <select class="w-24 text-sm form-select bg-transparent focus:bg-transparent" v-model="table.per_page" @change="setPageSize()">
            <option
                v-for="item in list"
                :key="item"
                :value="item"
            >
                {{ item }} rows
            </option>
        </select>
    </div>
</template>
<script>
    export default {
        props: {
            table: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                list: [10, 50, 100, 200, 500],
            }
        },
        methods: {
            setPageSize() {
                this.$emit('page-size', this.table.per_page);
            },
            renderNavItem(item) {
                if (!item.url && item.label === '...') {
                    return true;
                }

                if (parseInt(item.label)) {
                    return true;
                }

                return false;
            },
        },
    };
</script>